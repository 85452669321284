import React, { FunctionComponent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../Button";
import Field from "../Forms/Field";
import { Link } from "../Link";
import {
  SanityCtaLabelsInterface,
  SanityErrorMessagesLabelsInterface,
  SanityFormsLabelsInterface
} from "../../types/SanityTypes";
import Section from "../Section";
import { navigate } from "gatsby";
import { resetPassword } from "../../services/salesforce/Auth";
import LoaderIcon from "../../images/icons/loader";

import "./styles.scss";
import { sendErrorEvents } from "../../utils/sendErrorEvents";

export type ForgottenPasswordSubmitFormInterface = {
  login?: {
    _id: string;
    slug: {
      current: string;
    };
  };
} & SanityCtaLabelsInterface &
  SanityErrorMessagesLabelsInterface &
  SanityFormsLabelsInterface;

export const ForgottenPasswordSubmitForm: FunctionComponent<ForgottenPasswordSubmitFormInterface> = ({
  login,
  ctaLabels,
  formsLabels,
  errorMessages
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [resetToken, setResetToken] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccessfullySubmited, setIsSuccessfullySubmited] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm();

  const onSubmit = async (data: any) => {
    const { password } = data;
    setErrorMessage("");
    try {
      setIsLoading(true);
      const response = await resetPassword(password, resetToken);
      if (response.status === 200) {
        setIsSuccessfullySubmited(true);
        setTimeout(() => {
          navigate("/login");
          setIsSuccessfullySubmited(false);
        }, 5000);
      }
      if (response.status === 401) {
        setErrorMessage(response.data.message);
      }
    } catch (err) {
      console.log(err);
      setErrorMessage(err.response);
    } finally {
      setIsLoading(false);
    }
  };

  console.log({ errorMessage });

  const formFields: any = [
    {
      type: "password",
      name: "password",
      label: formsLabels?.password,
      placeholder: "must be 8 characters",
      rules: {
        required: true,
        pattern: {
          value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
          message: errorMessages?.passwordRequirements
        }
      },
      message: errorMessages?.passwordRequirements
    },
    {
      type: "password",
      name: "confirmpassword",
      label: formsLabels?.confirmpassword || "Confirm Password",
      placeholder: "repeat password",
      rules: {
        required: true,
        validate: (val: string) => {
          if (watch("password") != val) {
            return "Your password does not match";
          }
        },
        pattern: {
          value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
          message: errorMessages?.passwordRequirements
        }
      },
      message: errorMessages?.passwordRequirements
    }
  ];

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get("Token");
    if (token) {
      setResetToken(token);
    }
  }, []);

  const handleSubmitClick = () => {
    sendErrorEvents(errors, formFields);
  };

  return (
    <Section sectionClassName={"forgotten-password"}>
      <form onSubmit={handleSubmit(onSubmit)} className="forgotten-password__wrapper ">
        {formFields.map((field: any, index: number) => (
          <div className="row" key={index}>
            <Field key={field.name} {...field} registerInput={register} errors={errors} message={field.message} />
          </div>
        ))}

        {errorMessage && (
          <div className="forgotten-password__error">
            <p className="forgotten-password__error-info">{errorMessage}</p>
          </div>
        )}

        <div className="row forgotten-password-buttons">
          <Link
            _id={login?._id}
            to={login?.slug?.current || "/"}
            asButton={"nonary"}
            buttonForm="br-50"
            className={"forgotten-password__cancel"}
          >
            {ctaLabels?.cancel}
          </Link>
          <Button
            className="forgotten-password__submit"
            type="submit"
            form="br-50"
            disabled={isLoading}
            variant="nonary"
            onClick={handleSubmitClick}
          >
            {ctaLabels?.save}
            {isLoading && <LoaderIcon width={13} height={13} className="forgotten-password__reset-loaderIcon" />}
          </Button>
        </div>
        {isSuccessfullySubmited && <div className="success-message text__size-h3">Thank you! Password is changed.</div>}
      </form>
    </Section>
  );
};

export default ForgottenPasswordSubmitForm;
