import * as React from "react";
import Layout from "../../components/Layout";
import SEO from "../../components/Seo";
import Helmet from "react-helmet";
import StructuredData from "../../components/StructuredData";
import { graphql, PageProps, withPrefix } from "gatsby";
import { ImageInterface, InternalLink, LocalizedContextInterface, SanityColorList, Seo } from "../../types/SanityTypes";
import { LocalisedPageContext } from "../../types/PageTypes";
import HeroBanner from "../../components/HeroBanner";
import ForgottenPasswordSubmitForm from "../../components/ForgottenPasswordSubmitForm";
import ForgottenPasswordResetForm from "../../components/ForgottenPasswordResetForm";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { getLocalizedPath } from "../../utils/gatsbyBuild";

type ForgottenPasswordProps = {
  forgottenPassword: {
    _id: string;
    banner: {
      heading: string;
      subHeading?: string;
      contentAlignment: string;
      _rawHeroImage: ImageInterface;
      heroImage: ImageInterface;
      _rawHeroVideo: any;
      primaryColor: SanityColorList;
      isVideoBanner: boolean;
    };
    pageType: string;
    slug: {
      current: string;
    };
    seo: Seo;
  };
  sanityLogin: InternalLink;
} & LocalizedContextInterface;

type ForgottenPasswordPageContext = LocalisedPageContext;

export const query = graphql`
  query ForgottenPasswordPage($_id: String, $language: String) {
    forgottenPassword: sanityForgottenPassword(_id: { eq: $_id }) {
      _id
      banner {
        heading
        subHeading
        primaryColor {
          value
          title
        }
        contentAlignment
        isVideoBanner
        _rawHeroImage(resolveReferences: { maxDepth: 10 })
        heroImage {
          alt
          ...ImageWithPreview
        }
        _rawHeroVideo(resolveReferences: { maxDepth: 10 })
      }
      pageType
      slug {
        current
      }
      seo {
        metaTitle
        metaDescription
      }
    }
    sanitySiteSettings(i18n_lang: { eq: $language }) {
      name
      description
    }
    sanityLogin(i18n_lang: { eq: $language }) {
      _id
      slug {
        current
      }
    }
    ...LocalizedContext
  }
`;

type BlockRenderableSanityTypeNameToComponentMap = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [typeName: string]: React.FunctionComponent<any>;
};

const componentsMap: BlockRenderableSanityTypeNameToComponentMap = {
  reset: ForgottenPasswordResetForm,
  submit: ForgottenPasswordSubmitForm
};

const ForgottenPassword = (props: PageProps<ForgottenPasswordProps, ForgottenPasswordPageContext>) => {
  const language = props.pageContext.language;
  const { htmlLang, siteUrl } = useSiteMetadata(language);
  const forgottenPasswordPage = props.data.forgottenPassword;
  const localizedPath = withPrefix(getLocalizedPath(forgottenPasswordPage.slug?.current, forgottenPasswordPage._id));
  const { pageType } = forgottenPasswordPage;
  const getComponent = ({ pageType }: { pageType: string }) => {
    const component = componentsMap[pageType];
    return component ? component : null;
  };

  const FormComponent = getComponent({ pageType });

  return (
    <Layout
      localizedContext={{ ...props.pageContext, ...props.data }}
      pageName={forgottenPasswordPage.banner?.heading}
      pageType={"forgottenPasswordPage"}
    >
      <StructuredData
        type={"forgottenPasswordPagee"}
        data={forgottenPasswordPage.banner._rawHeroImage}
        language={htmlLang}
        slug={`/${forgottenPasswordPage.slug.current}/`}
      />
      <Helmet>
        <link rel="canonical" href={`${siteUrl}${localizedPath}`} />
      </Helmet>
      <SEO
        title={forgottenPasswordPage.seo.metaTitle}
        description={forgottenPasswordPage.seo.metaDescription}
        imageUrl={forgottenPasswordPage.banner._rawHeroImage.asset.url}
        imageAlt={forgottenPasswordPage.banner._rawHeroImage.alt}
      />
      <div className="page_reset-password">
        <HeroBanner
          image={forgottenPasswordPage.banner?._rawHeroImage}
          sanityImage={forgottenPasswordPage.banner?.heroImage}
          title={forgottenPasswordPage.banner?.heading}
          alignment={forgottenPasswordPage.banner?.contentAlignment}
          isVideoBanner={forgottenPasswordPage.banner?.isVideoBanner}
          video={forgottenPasswordPage.banner?._rawHeroVideo}
        />
        {FormComponent
          ? React.createElement(FormComponent, {
              login: props.data.sanityLogin,
              ctaLabels: props.data.sanityLabels?.ctaLabels,
              formsLabels: props.data.sanityLabels?.formsLabels,
              errorMessages: props.data.sanityLabels?.errorMessages
            })
          : null}
      </div>
    </Layout>
  );
};

export default ForgottenPassword;
