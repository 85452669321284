import React, { FunctionComponent, useState } from "react";
import Section from "../Section";
import { Field } from "../Forms";
import { useForm } from "react-hook-form";
import {
  SanityCtaLabelsInterface,
  SanityErrorMessagesLabelsInterface,
  SanityFormsLabelsInterface
} from "../../types/SanityTypes";
import Button from "../Button";
import { Link } from "../Link";
import { resetPasswordEmailToken } from "../../services/salesforce/Auth";
import LoaderIcon from "../../images/icons/loader";

import "./styles.scss";

type ForgottenPasswordResetFormInterface = {
  login?: {
    _id: string;
    slug: {
      current: string;
    };
  };
} & SanityCtaLabelsInterface &
  SanityErrorMessagesLabelsInterface &
  SanityFormsLabelsInterface;

const ForgottenPasswordResetForm: FunctionComponent<ForgottenPasswordResetFormInterface> = props => {
  const { ctaLabels, formsLabels, errorMessages, login } = props;
  const [formSubmitLoading, setFormSubmitLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleFormSubmit = async ({ email }: { email: string }) => {
    setFormSubmitLoading(true);
    try {
      const response = await resetPasswordEmailToken(email);
      if (response.status === 200) {
        setSuccessMessage(
          "Thanks for submitting your email address. We've sent you an email with the information needed to reset your password. The email might take a couple of minutes to reach your account. Please check your junk mail to ensure you receive it."
        );
        setErrorMessage("");
      }
      if (response.status === 400) {
        setSuccessMessage("");
        setErrorMessage("Email address not found");
      }
    } catch (err) {
      setSuccessMessage("");
      setErrorMessage("Form submission failed, please try again");
    } finally {
      setFormSubmitLoading(false);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const emailFormFields = {
    type: "text",
    name: "email",
    label: formsLabels?.email,
    placeholder: "Enter your email",
    rules: {
      required: true,
      pattern: {
        value:
          /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: errorMessages?.validEmail || "Please enter your email!"
      }
    },
    message: errorMessages?.validEmail
  };

  return (
    <Section sectionClassName="forgotten-password__reset">
      <div className="forgotten-password__reset-wrapper">
        <div className="forgotten-password__reset-info">
          <h3 className="forgotten-password__reset-info-title">Forgot Password ?</h3>
          <p className="forgotten-password__reset-info-message">
            Don’t worry! It happens. Please enter the email associated with your account.
          </p>
        </div>
        <form className="forgotten-password__reset-form" onSubmit={handleSubmit(handleFormSubmit)}>
          <Field
            className="input-email"
            key={emailFormFields.name}
            {...emailFormFields}
            registerInput={register}
            errors={errors}
            message={emailFormFields.message}
          />
          <Button
            variant="nonary"
            type="submit"
            size="lg"
            form="br-50"
            className="forgotten-password__reset-submit"
            disabled={formSubmitLoading}
          >
            {ctaLabels?.send}
            {formSubmitLoading && (
              <LoaderIcon width={13} height={13} className="forgotten-password__reset-loaderIcon" />
            )}
          </Button>
        </form>
        <div className="forgotten-password__reset__message">
          {successMessage && <p className="forgotten-password__reset__message-success">{successMessage}</p>}
          {errorMessage && <p className="forgotten-password__reset__message-error">{errorMessage}</p>}
        </div>
        <div className="forgotten-password__reset-login">
          <span>
            Remember password?
            <Link _id={login?._id} to={login?.slug?.current || "/"} className={"forgotten-password__reset-login-link"}>
              {ctaLabels?.login}
            </Link>
          </span>
        </div>
      </div>
    </Section>
  );
};

export default ForgottenPasswordResetForm;
