import React from "react";
import articleExtractor from "./extractors/article";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import productExtractor from "./extractors/product";
import pageExtractor from "./extractors/page";
import faqExtractor from "./extractors/faq";
import organisationExtractor from "./extractors/organisation";

interface StructuredDataInterface {
  type: string;
  name?: string;
  headline?: string;
  description?: string;
  slug?: string;
  image?: {
    url: string;
  };
  data?: Record<string, unknown>;
  language: string;
  reviews?: {
    averageRating: string;
    reviewCount: string;
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const extractorsMap: any = {
  Article: articleExtractor,
  Product: productExtractor,
  Organisation: organisationExtractor,
  FAQPage: faqExtractor,
  default: pageExtractor
};

const StructuredData = ({
  type,
  name,
  headline,
  description,
  slug,
  image,
  data,
  language,
  reviews
}: StructuredDataInterface) => {
  const { siteUrl, siteName } = useSiteMetadata();

  const extractor = extractorsMap[type] || extractorsMap.default;

  const jsonLd = Object.assign(
    {
      "@context": "http://schema.org",
      "@type": type
    },
    extractor(slug, data, language, siteUrl, siteName, reviews)
  );

  if (headline) jsonLd.headline = headline;
  if (name) jsonLd.name = name;
  if (description) jsonLd.description = description;
  if (image)
    jsonLd.image = {
      "@type": "ImageObject",
      url: image.url
    };

  return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }} />;
};

export default StructuredData;
