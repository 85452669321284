const pageExtractor = (slug: string, siteName: string, siteUrl: string, data?: Record<string, unknown>) => {
  const url = `${siteUrl}${slug}`;

  return {
    "@id": url,
    primaryImageOfPage: {
      "@type": "ImageObject",
      contentUrl: (data?.asset as Record<string, unknown>)?.url
    },
    reviewedBy: {
      "@type": "Organization",
      name: "Sure Deodorant"
    }
  };
};

export default pageExtractor;
